html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Yantramanav', sans-serif;
    color: var(--text-primary-color);
}
h1,
h2,
h4 {
    font-family: 'Roboto Slab', serif;
}
h3,
p,
li {
    font-family: 'Yantramanav', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #0a3ab3;
    --heading-primary-color: #0035ba;
    --bg-primary-color: #e2eafa;
    --link-primary-color: #0a3ab3;
    --button-primary-color: #fffb91;
}

h1 {
    color: var(--heading-primary-color);
    font-weight: 700;
    font-size: 60px;
    line-height: 79px;
    margin: 20px 0;
}
h2 {
    color: var(--heading-primary-color);
    font-weight: 700;
    font-size: 48px;
    line-height: 63px;
    margin: 15px 0;
}
h3 {
    color: var(--heading-primary-color);
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    margin: 15px 0;
}
h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}
p,
li {
    color: var(--text-primary-color);
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    padding: 10px 0;
}
@media only screen and (max-width: 867px) {
    h1 {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
    h2 {
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
    }
    h3 {
        font-size: 23.5409px;
        line-height: 31px;
        text-align: center;
    }
    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
}
.thin {
    font-weight: 300;
}
.text-center {
    text-align: center;
}
.text-white {
    color: white;
}
.bg-light-blue {
    background: var(--bg-primary-color);
}
.bg-dark-blue {
    background: var(--heading-primary-color);
}
.block {
    padding: 50px 0;
}
.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
}
.col1-2 {
    grid-template-columns: 1fr 2fr;
}
.col2-1 {
    grid-template-columns: 2fr 1fr;
}
.col2 {
    grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 867px) {
    .col1-2,
    .col2-1,
    .col2 {
        grid-template-columns: 1fr;
    }
}
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}
@media (min-width: 1400px) {
    .container {
        min-width: 1360px;
    }
}
@media only screen and (max-width: 1200px) {
    .container {
        padding: 0 20px;
    }
}

/* Buttons */
.btn {
    background: #ffffff;
    color: var(--heading-primary-color);
    border: 2px solid var(--heading-primary-color);
    box-sizing: border-box;
    border-radius: 40px;
    padding: 20px 39px;
    transition: all 0.5s ease-in-out;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}
.btn.primary {
    background: var(--button-primary-color);
}
.btn:hover {
    background: var(--button-primary-color);
    color: var(--heading-primary-color);
}
.btn-row {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
}
.cnt-btn {
    margin: 60px 0;
}

@media only screen and (max-width: 867px) {
    .btn-row {
        display: grid;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .btn {
        font-size: 18px;
        line-height: 16px;
    }
    .cnt-btn .btn {
        display: block;
    }
    .cnt-btn {
        margin: 30px 0 20px;
    }
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    padding: 0;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-decoration: none;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

/* Header */

.site-header {
    width: 100%;
    top: 0;
    left: 0;
    padding: 30px 0;
    z-index: 20;
    position: relative;
}
.site-header .container,
footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.site-header .site-logo,
.site-header .site-nav {
    z-index: 1;
}
.site-logo,
footer .site-logo {
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    color: var(--heading-primary-color);
    position: relative;
    vertical-align: middle;
    text-decoration: none;
}
.site-logo:before,
footer .site-logo:before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 28px;
}
.site-logo:before {
    background-image: url(../image/logo_blue.svg);
}
footer .site-logo:before {
    background-image: url(../image/logo_yellow.svg);
}
.site-nav > ul {
    display: flex;
    list-style: none outside none;
}
.site-nav > ul > li {
    position: relative;
    margin: 0 40px;
}
.site-nav > ul > li:last-child {
    margin-right: 0;
}
.site-nav > ul > li a {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 31px;
    color: var(--link-primary-color);
    text-decoration: none;
    transition: 0.5s;
}
.site-nav > ul > li a:hover {
    font-weight: 700;
}
.site-nav .mob-email {
    display: none;
}
@media only screen and (max-width: 1067px) {
    .site-header .site-nav {
        z-index: auto;
        position: unset;
    }
    .nav-visible .site-logo {
        padding-top: 10px;
    }
    .site-nav .icon-box {
        background: #0035ba;
        border-bottom-left-radius: 15px;
        width: 70px;
        height: 60px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .site-nav .icon {
        border-top: 3.5px solid #ffffff;
        height: 24px;
        width: 30px;
        box-sizing: border-box;
        position: absolute;
        z-index: 30;
        right: 20px;
        top: 18px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .site-nav .icon:before {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 30px;
        right: 0;
        background: #ffffff;
        top: 8px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .site-nav .icon:after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 30px;
        right: 0;
        background: #ffffff;
        bottom: -1px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .nav-visible .site-nav .icon {
        border: transparent;
    }
    .nav-visible .site-nav .icon:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -khtml-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 32px;
        right: -1px;
    }
    .nav-visible .site-nav .icon:after {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -khtml-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        width: 32px;
        right: 0px;
        top: 8px;
    }
    .nav-visible .site-nav {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        z-index: 5;
        background: var(--heading-primary-color);
    }
    .site-header.nav-visible .container {
        display: flow-root;
    }
    .site-nav > ul {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
    .nav-visible .site-nav > ul {
        display: grid;
        justify-content: center;
        align-items: center;
        opacity: 1;
        visibility: visible;
        position: fixed;
        width: 70%;
        height: 50%;
        top: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        margin: 0 auto;
    }
    .nav-visible .site-nav > ul > li {
        padding: 15px;
    }
    .nav-visible .site-nav > ul > li:hover {
        background: #00aae533;
        border-radius: 22px;
    }
    .nav-visible .site-nav > ul > li:hover a {
        color: white;
    }
    .nav-visible .site-nav > ul > li:last-child {
        margin: 0 40px;
    }
    .nav-visible .site-nav > ul > li:before {
        content: none;
    }
    .nav-visible .site-nav > ul > li a {
        font-size: 23px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
    }

    .nav-visible .btn-group {
        box-shadow: none;
    }
    .nav-visible .site-nav > ul > li a.btn,
    .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn,
    .nav-visible .btn-group li:not(:first-child) .btn {
        border: none;
        background-color: transparent;
    }
}

/* Intro */
.intro {
    padding: 30px 0;
}
.intro p {
    width: 90%;
}
.main-image img {
    max-width: 100%;
    height: auto;
}
@media only screen and (max-width: 867px) {
    .intro {
        padding: 0px 0px 30px 0;
    }
    .intro h1 {
        display: none;
    }
    .intro p {
        width: 100%;
        text-align: center;
    }
    .main-image {
        grid-row: 1;
        position: relative;
    }
    .main-image img {
        display: block;
        margin: 90px auto 0;
    }
    .main-image[data-title]:before {
        content: attr(data-title);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #0035ba;
    }
    .main-image[data-subtitle]:after {
        content: attr(data-subtitle);
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #0035ba;
    }
}
/* Image section */
.bg-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 400px;
}
#are-nfts-secure .bg-image {
    background-image: url(../image/are-nfts-secure.svg);
}
#crypto-wallet .bg-image {
    background-image: url(../image/crypto_wallet.svg);
}
#authentication .bg-image {
    background-image: url(../image/authentication.svg);
}
#about-us .bg-image {
    background-image: url(../image/about_us.svg);
}
/* How to avoid section */
.row {
    display: flex;
    gap: 50px;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 40px 0;
}
.widht60 {
    width: 60%;
    margin: 0 auto;
}
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
@media only screen and (max-width: 867px) {
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .block {
        padding: 40px 0;
    }
    .widht60 {
        width: 100%;
    }
}

/* Slider */
.cases {
    position: relative;
    overflow: hidden;
}
.cases:before {
    height: 20% !important;
}
.slider {
    display: grid;
    gap: 40px;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 60px;
    position: relative;
}
.inner {
    background: #ffffff;
    box-shadow: 0px 4px 165px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 30px;
}

/* DRAGAN  */

.inner.active .transperant::after {
    position: relative;
}

.slider-nav {
    display: none;
}
@media only screen and (max-width: 867px) {
    .slider {
        display: flex;
    }
    .slider .mytabs {
        width: 100%;
    }
    .mytabs.active {
        display: block;
        height: auto;
        opacity: 1;
        transition: opacity 0.3s linear;
        float: left;
    }
    .mytabs {
        display: none;
        height: 0;
        opacity: 0;
    }
    .slider-nav {
        width: 100%;
        margin-top: 40px;
        justify-content: center !important;
        display: block;
        position: relative;
    }
    .slider-nav li + li {
        margin-left: 15px;
    }
    .slider-nav ul,
    ul.slider-nav {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .slider-nav ul li,
    ul.slider-nav li {
        background-color: #e2eafa;
        border-radius: 12px;
        height: 14px;
        list-style-type: none;
        width: 14px;
        cursor: pointer;
        padding: 0;
    }
    .slider-nav ul li.active,
    ul.slider-nav li.active {
        background-color: var(--heading-primary-color);
    }
}
/* Read more */
.m-readmore-btn {
    display: block;
    margin: 20px 0;
    width: 100%;
}
.slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}
.transperant {
    position: relative;
}
.transperant:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 4%, rgba(255, 255, 255, 0) 60%);
    pointer-events: none; /* so the text is still selectable */
}

.hidden {
    display: none !important;
}
@media only screen and (max-width: 867px) {
    .m-readmore-btn {
        background: var(--button-primary-color);
    }
}
/* F&Q */
.faq-wrapper {
    margin-top: 60px;
    position: relative;
}
.faq-wrapper:before,
.cases:before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 120px;
    bottom: 0;
    left: 0;
}
.faq-listing {
    background: linear-gradient(226.28deg, #ffffff 5.31%, #f4f6f8 97.68%);
    box-shadow: 2px 17px 31px rgba(56, 56, 56, 0.15);
    border-radius: 15px;
    margin-top: 30px;
    padding: 20px;
    position: relative;
}

.faq {
    padding: 10px 30px;
    transition: all 0.5s ease-in;
    border: none;
}
.faq:not(:first-of-type) {
    border-top: 1px solid #e5eff9;
}
.faq.expanded {
    background: #eaf1ff;
    border-radius: 15px;
    opacity: 1;
    height: auto;
}
.faq.faq.expanded + .faq {
    border-top: 0px;
}

.faq.collapsed .faq-body {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    height: 0;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq.expanded .faq-title h3 {
    font-weight: 700;
}

.faq .faq-title h3 {
    pointer-events: none;
    font-family: 'Yantramanav';
    font-weight: 300;
    font-size: 30px;
    line-height: 39px;
}

.faq-btn {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 2px solid #0035ba;
    box-sizing: border-box;
    background-image: url(../image/plus_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    transition: all 0.5s ease-in-out;
}
.faq.expanded .faq-btn {
    background-color: var(--button-primary-color);
    background-image: url(../image/minus_icon.svg);
}
.faq-btn:hover {
    background-color: var(--button-primary-color);
}
.faq-btn:hover {
    cursor: pointer;
}
.read-more-faq {
    width: 100%;
    border: 1px solid var(--link-primary-color);
    border-radius: 69px;
    height: 59px;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    color: var(--link-primary-color);
}

.read-more-faq:active {
    background-color: #e2fcfc;
}
@media only screen and (max-width: 867px) {
    .faq-listing {
        background: none;
        box-shadow: none;
        margin-top: 30px;
        padding: 0;
    }
    .faq-wrapper:before {
        content: none;
    }
    .faq {
        padding: 10px;
    }
    .faq {
        border-top: 1px solid #ffffff !important;
    }
    .faq .faq-title h3 {
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }
    .faq-btn {
        border: none;
    }
    .faq.expanded .faq-btn {
        background-color: transparent;
    }
    .faq.expanded {
        background: #ffffff;
        border-radius: 15px;
    }
    .faq.expanded .faq-body p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
    .faq.expanded + .faq {
        border: none !important;
    }
}
/* Footer */
footer .info {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-top: 20px;
    width: 60%;
}
footer .site-logo {
    color: white;
}
footer h4 {
    margin-bottom: 20px;
}
footer ul {
    list-style: none;
}
footer li {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 15px 0;
    position: relative;
    opacity: 1;
}
footer li a {
    color: inherit;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}
footer li:after {
    content: '';
    transition: all 0.5s ease-in-out;
    opacity: 0;
}
footer li:hover:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    height: 1px;
    width: 85px;
    background: #fffc80;
    border-radius: 46px;
}
@media only screen and (max-width: 867px) {
    footer .grid {
        text-align: center;
        gap: 80px;
    }
    footer .info {
        width: 100%;
        padding: 0 20px;
        font-size: 16px;
        line-height: 22px;
    }
    footer li:hover:after {
        margin: 0 auto;
        right: 0;
        width: 20%;
        bottom: -7px;
    }
    footer .logo-wrapper,
    footer .general div:first-of-type {
        position: relative;
    }
    footer .logo-wrapper:after,
    footer .general div:first-of-type:after {
        content: '';
        position: absolute;
        bottom: -40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 1px;
        width: 60%;
        background: #eaf1ff;
        border-radius: 46px;
    }
}
